import {notifications} from '@mantine/notifications';
import jwt_decode from 'jwt-decode';

import {DRAFT_INITIAL_STATE} from '../models/models';

import {MAX_UPLOAD_SIZE} from './constants';
import {STORAGE} from './localStorage';

export const isStringEmpty = (str) => {

    if (str === undefined || str === null) {
        return true;
    }

    if (typeof str === 'string') {
        return str.trim().length === 0;
    }

    return false;
};

export const isTokenExpired = (num) => {
    return Math.floor(new Date().getTime() / 1000) >= num;
};

export const getAuth = (permission = '*') => {
    let decoded = null;
    let isAuth = false;
    let userName = '';
    let userEmail = '';
    let userCode = '';
    let permissions = [];
    let utcOffset = -3;
    const tokenStorage = STORAGE.get(STORAGE.TOKEN);

    if (tokenStorage !== null && !isStringEmpty(tokenStorage)) {
        decoded = jwt_decode(tokenStorage);
        if (isTokenExpired(decoded?.exp ?? '')) {
            STORAGE.remove(STORAGE.TOKEN);
            window.location.replace('/');
        }
        userEmail = decoded?.user_email ?? '';
        userName = decoded?.user_username ?? '';
        userCode = decoded?.user_code ?? '';
        permissions = decoded?.authorities?.split(',') ?? [];

        if (permission === '*' || (permissions.includes(permission) ?? false)) {
            isAuth = true;
        }
    }

    return [isAuth, userEmail, permissions, userCode, userName, tokenStorage, utcOffset];
};

export const isAuthenticated = () => {
    const tokenStorage = STORAGE.get(STORAGE.TOKEN);

    if (tokenStorage !== null && !isStringEmpty(tokenStorage)) {
        const decoded = jwt_decode(tokenStorage);
        if (isTokenExpired(decoded?.exp ?? '')) {
            STORAGE.remove(STORAGE.TOKEN);
            window.location.replace('/');
            return false;
        }
        return true;
    }

    return false;
};

export async function fileToBase64(file) {
    return new Promise((resolve, reject) => {
        if (!file) {
            resolve(null);
            return;
        }

        const reader = new FileReader();
        reader.onload = () => {
            const base64data = reader.result.split(',')[1];
            resolve(base64data);
        };
        reader.onerror = reject;

        reader.readAsDataURL(file);
    });
}

export const base64toBlob = (base64Data) => {
    const sliceSize = 1024;
    const byteCharacters = atob(base64Data);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
        const begin = sliceIndex * sliceSize;
        const end = Math.min(begin + sliceSize, bytesLength);

        const bytes = new Array(end - begin);
        for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
            bytes[i] = byteCharacters[offset].charCodeAt(0);
        }
        byteArrays[sliceIndex] = new Uint8Array(bytes);
    }

    return new Blob(byteArrays, {type: 'application/pdf'});
};

export const cleanString = (str) => {
    return str.replace(/[^a-zA-Z0-9. ]/g, '');
};

export const viewDocument = (doc) => {
    const {nombre, type, file} = doc;
    const blob = base64toBlob(file);
    const blobUrl = URL.createObjectURL(blob);

    if (nombre.toLowerCase().endsWith('.pdf')) {
        const newWindow = window.open(blobUrl, '_blank');
        newWindow.opener = null;
        newWindow.focus();
    } else {
        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = nombre;
        link.click();
    }
};

export const handleDownload = (attachment, downloadPrefix = 'cotizador') => {
    const byteCharacters = atob(attachment.content); // Decodificar base64
    const byteNumbers = new Uint8Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const blob = new Blob([byteNumbers], {type: 'application/octet-stream'});
    const blobUrl = URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = blobUrl;
    a.download = `${downloadPrefix}-${attachment.name}`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    URL.revokeObjectURL(blobUrl);
};

export const calculateAmountByChargeable = (transportMode, mode, chargeable, amountPerKg) => {
    if (transportMode === 'AIR') {
        // Multiply by chargeable
        return Number((amountPerKg * Number(chargeable)).toFixed(2));
    } else if (mode === 'LCL') {
        // Multiply by chargeable divided by 1000 because tariff is in weighted tons
        return Number((amountPerKg * Number(chargeable / 1000)).toFixed(2));
    }
    return 0;
};

export const viewShipmentDocument = (doc) => {
    const {name, type, data} = doc;
    const blob = base64toBlob(data);
    const blobUrl = URL.createObjectURL(blob);

    if (name.toLowerCase().endsWith('.pdf')) {
        const newWindow = window.open(blobUrl, '_blank');
        newWindow.opener = null;
        newWindow.focus();
    } else {
        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = name;
        link.click();
    }
};

export const validateNumericInput = (value) => {
    let sanitizedValue = value.replace(/[^0-9.]/g, '');
    const dotCount = sanitizedValue.split('.').length - 1;
    if (dotCount > 1) {
        const parts = sanitizedValue.split('.');
        sanitizedValue = parts[0] + '.' + parts.slice(1).join('');
    }

    return sanitizedValue;
};

export const validateNumericInputWithComma = (value) => {
    if (value === undefined || value === null) return value;
    if (typeof value === 'number') return value;

    let sanitizedValue = value.replace(/,/g, '.');
    return validateNumericInput(sanitizedValue);
};

export const validateDate = (date) => {
    var regex_date = /^\d{2}\/\d{2}\/\d{4}$/;
    if (!regex_date.test(date)) {
        return false;
    }
    return true;
};

export const parseDataNameToSelect = (data) => {
    return data?.map(d => {
        return {
            ...d,
            value: d.name,
            label: d.name.replace('_', ' '),
        };
    });
};

export const parseDataIdDescriptionToSelect = (data) => {
    return data?.map(d => {
        return {
            ...d,
            value: d.id?.toString(),
            label: d.description.replace('_', ' '),
        };
    });
};

export const addDraft = (quotation, type, name) => {
    let draft = DRAFT_INITIAL_STATE;

    const reducedQuotation = {
        ...quotation,
        docs: []
    };

    draft = {
        ...draft,
        id: STORAGE.getAutoIncrement(STORAGE.DRAFTS_AUTOINCREMENT),
        type: type,
        date: new Date(Date.now()),
        name: name,
        display: {
            type: reducedQuotation?.transportMode,
            mode: reducedQuotation?.modo,
            origin: reducedQuotation?.origenCodigo,
            destination: reducedQuotation?.destinoCodigo,
            incoterm: reducedQuotation?.incoterm,
            volume: reducedQuotation?.cbmTotal,
            weight: reducedQuotation?.pesoTotal,
            chargeable: reducedQuotation?.chargeableTotal,
            client: reducedQuotation?.client?.nombreFantasia,
        },
        data: reducedQuotation
    };

    let drafts = STORAGE.get(STORAGE.DRAFTS);

    if (!drafts) {
        drafts = [];
    }

    drafts.push(draft);

    STORAGE.set(STORAGE.DRAFTS, drafts);

    notifications.show({
        title: 'Borrador guardado!',
        message: 'Borrador guardado con éxito.',
        color: 'green',
    });
};

export const addExpressDraft = (quotation, type, name, client) => {
    let draft = DRAFT_INITIAL_STATE;

    const reducedQuotation = {
        ...quotation,
        client: client,
        docs: []
    };

    draft = {
        ...draft,
        id: STORAGE.getAutoIncrement(STORAGE.DRAFTS_AUTOINCREMENT),
        type: type,
        date: new Date(Date.now()),
        name: name,
        display: {
            type: 'EXPRESS',
            mode: '',
            origin: reducedQuotation?.shipperAddress?.countryCode,
            destination: reducedQuotation?.recipientAddress?.countryCode,
            incoterm: '',
            volume: reducedQuotation?.cbmTotal,
            weight: reducedQuotation?.pesoTotal,
            chargeable: reducedQuotation?.chargeableTotal,
            client: reducedQuotation?.client?.nombreFantasia,
        },
        data: reducedQuotation
    };

    let drafts = STORAGE.get(STORAGE.DRAFTS);

    if (!drafts) {
        drafts = [];
    }

    drafts.push(draft);

    STORAGE.set(STORAGE.DRAFTS, drafts);

    notifications.show({
        title: 'Borrador guardado!',
        message: 'Borrador guardado con éxito.',
        color: 'green',
    });
};

export const isFileSizeValid = (fileSize) => {
    return fileSize <= MAX_UPLOAD_SIZE.value;
};

// Parses Java LocalDateTime object with format [Year, month, day, hour, minute, second] to JavaScript Date
export const parseJavaLocalDateTimeToDate = (localDateTime) => {
    if (!localDateTime) return null;
    return new Date(localDateTime[0], localDateTime[1] - 1, localDateTime[2], localDateTime[3], localDateTime[4], localDateTime[5]);
};

export const dateToString = (date) => {
    return date.toISOString().split('T')[0];
};

// Returns true if the value is equal to "true"
export const booleanEqualsTrue = (value) => {
    return value.toString() === 'true';
};

export const calculatePercentage = (value, total) => {
    return (value / total) * 100;
};

export const addOrUpdateNote = (existentNotes, newNote) => {
    const index = existentNotes.findIndex(note => note.type === newNote.type);

    const isAdd = index === -1;

    if (index !== -1) {
        return [
            ...existentNotes.slice(0, index),
            newNote,
            ...existentNotes.slice(index + 1)
        ];
    } else {
        return [...existentNotes, newNote];
    }
};

/**
 * Get the value of a note by type
 * @description if the note exists, return the note value, otherwise return an empty string
 * @returns {string}
 */
export const getNoteValueByType = (notes, type) => {
    const note = notes.find(n => n.type === type);
    return note ? note.value : '';
};

/**
 * Check if a note with certain type exists and it's value is not empty
 * @returns {boolean}
 */
export const noteExistsAndIsNotEmpty = (notes, type) => {
    if (!Array.isArray(notes)) return false;
    const note = notes.find(n => n.type === type);
    return note && note.value && note.value.trim().length > 0;
};

export const isArrayEmpty = (array) => {
    return Array.isArray(array) && array.length === 0;
};

export const capitalizeEachWord = (str) => {
    let splitStr = str.toLowerCase().split(' ');
    for (let i = 0; i < splitStr.length; i++) {
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(' ');
};

export const formatWithLineBreaks = (str) => {
    return str.split(/(?:\r\n|\r|\n)/g).map((line, index) => (
        <>
            {index > 0 && <br />}
            {line}
        </>
    ));
};
