import {Divider, Paper, Textarea} from '@mantine/core';
import {Dropzone, IMAGE_MIME_TYPE, MS_EXCEL_MIME_TYPE, MS_POWERPOINT_MIME_TYPE, MS_WORD_MIME_TYPE, PDF_MIME_TYPE} from '@mantine/dropzone';
import {notifications} from '@mantine/notifications';
import {IconPhoto} from '@tabler/icons-react';
import {useEffect, useState} from 'react';

import AttachmentItem from '../../components/base/attachment/AttachmentItem';
import Button from '../../components/base/Button/Button';
import Container from '../../components/base/Container/Container';
import Select from '../../components/base/Select/Select';
import Text from '../../components/base/Text/Text';
import {useLanguage} from '../../contexts/LanguageContext';
import productController from '../../controller/support-service/productController';
import ticketController from '../../controller/support-service/ticketController';
import usePasteFile from '../../hooks/utils/usePasteFile';
import {TICKET_INITIAL_STATE} from '../../models/models';
import {validateNewTicket} from '../../schemas/support/new_ticket';
import {MAX_UPLOAD_SIZE} from '../../util/constants';
import {fileToBase64} from '../../util/functions';
import {TRANSLATION_KEYS} from '../../util/translation-keys';

export default function SupportNewTicket({isOpen, setCreateNew, clearFn}) {
    const {t, language} = useLanguage();
    const [ticket, setTicket] = useState(TICKET_INITIAL_STATE);
    const [products, setProducts] = useState([]);
    const [errors, setErrors] = useState([]);
    const [attachments, setAttachments] = useState([]);
    const {handlePaste} = usePasteFile(setAttachments);
    const [isSendingMessage, setIsSendingMessage] = useState(false);

    useEffect(() => {
        if (isOpen) {
            const getAllProductsAndRelatedErrors = async () => {
                try {
                    let data = await productController.getProducts();

                    const translateResultName = (name) => {
                        let key = `SUPPORT_PRODUCT_${name}`;
                        return t(TRANSLATION_KEYS[key]);
                    };

                    let results = data?.map((result) => (
                        {value: result.name, label: translateResultName(result.name), original: result}
                    ));

                    setProducts(results);
                } catch (e) {
                    notifications.show({
                        title: t(TRANSLATION_KEYS.ERROR_ALERT),
                        message: t(TRANSLATION_KEYS.SUPPORT_ERROR_WHILE_GETTING_PRODUCTS),
                        color: 'red',
                        autoClose: 5000,
                    });
                }
            };

            getAllProductsAndRelatedErrors();
        }
    }, [language, isOpen]);

    useEffect(() => {
        const getRelatedErrorsByProductName = async () => {
            try {
                let data = await productController.getRelatedErrorsByProductName(ticket?.product?.name);

                const translateResultName = (name) => {
                    let key = `SUPPORT_PRODUCT_ERROR_${name}`;
                    return t(TRANSLATION_KEYS[key]);
                };

                let results = data?.map((result) => (
                    {value: result.name, label: translateResultName(result.name), original: result}
                ));

                setErrors(results);
            } catch (e) {
                notifications.show({
                    title: t(TRANSLATION_KEYS.ERROR_ALERT),
                    message: t(TRANSLATION_KEYS.SUPPORT_ERROR_WHILE_GETTING_PRODUCTS),
                    color: 'red',
                    autoClose: 5000,
                });
            }
        };
        if (ticket?.product?.name) {
            getRelatedErrorsByProductName();
            setTicket({...ticket, error: null});
        }
    }, [ticket.product]);

    const handleSave = async () => {
        const validationErrors = validateNewTicket(ticket);

        if (validationErrors) {
            validationErrors.map((error) => {
                notifications.show({
                    title: t(TRANSLATION_KEYS.ERROR_ALERT),
                    message: t(error.message),
                    color: 'red',
                    autoClose: 5000,
                });
            });

            return;
        }

        try {
            setIsSendingMessage(true);
            await ticketController.createTicket(ticket);

            notifications.show({
                title: t(TRANSLATION_KEYS.SUCCESS_ALERT),
                message: t(TRANSLATION_KEYS.SUPPORT_CREATE_TICKET_SUCCESS),
                color: 'green',
                autoClose: 5000,
            });
        } catch (e) {
            notifications.show({
                title: t(TRANSLATION_KEYS.ERROR_ALERT),
                message: t(TRANSLATION_KEYS.SUPPORT_CREATE_TICKET_ERROR),
                color: 'red',
                autoClose: 5000,
            });
        } finally {
            clearFn && clearFn();
            setTicket(TICKET_INITIAL_STATE);
            setAttachments([]);
            setProducts([]);
            setErrors([]);
            setIsSendingMessage(true);
        }
    };

    const handleCancel = () => {
        setCreateNew(false);
    };

    useEffect(() => {
        const parseToBase64 = async () => {
            try {
                const base64Attachments = await Promise.all(
                    attachments.map(async (attachment) => {
                        return {
                            name: attachment.name,
                            content: await fileToBase64(attachment),
                        };
                    })
                );
                setTicket({...ticket, attachments: base64Attachments});
            } catch (error) {
                notifications.show({
                    title: t(TRANSLATION_KEYS.ERROR_ALERT),
                    message: t(TRANSLATION_KEYS.SYSTEM_DROPZONE_ERROR),
                    color: 'red',
                    autoClose: 5000,
                });
            }
        };

        if (attachments.length > 0) {
            parseToBase64();
        }
    }, [attachments]);

    const handleRemoveAttachment = (name) => {
        let filteredAttachments = attachments.filter((a) => a.name !== name);
        setAttachments(filteredAttachments);
    };

    return (
        <Container clean fw p={8} gap={8} direction={'column'} data={'visible'}>
            <Container clean fw gap={8}>
                <Select
                    w={'50%'}
                    label={t(TRANSLATION_KEYS.PRODUCT)}
                    placeholder={t(TRANSLATION_KEYS.PRODUCT)}
                    data={products}
                    value={ticket?.product?.name || null}
                    onChange={(e) => (
                        setTicket({...ticket, product: products.find((product) => product.original.name === e).original})
                    )}
                    required
                />
                <Select
                    w={'50%'}
                    label={t(TRANSLATION_KEYS.ERROR_TYPE)}
                    placeholder={t(TRANSLATION_KEYS.ERROR_TYPE)}
                    data={errors}
                    value={ticket.error?.name || null}
                    onChange={(e) => (
                        setTicket({...ticket, error: errors.find((error) => error.original.name === e).original})
                    )}
                    required
                />
            </Container>
            <Textarea
                size={'xs'}
                label={t(TRANSLATION_KEYS.DESCRIPTION)}
                placeholder={t(TRANSLATION_KEYS.DESCRIPTION)}
                value={ticket.description}
                onChange={(e) => {
                    setTicket({...ticket, description: e.currentTarget.value});
                }}
                onPaste={(e) => handlePaste(e)}
                autosize
                maxRows={5}
                required
            />
            <Dropzone
                onDrop={(files) => setAttachments(files)}
                onReject={(files) =>
                    notifications.show({
                        title: t(TRANSLATION_KEYS.ERROR_ALERT),
                        message: t(TRANSLATION_KEYS.SYSTEM_DROPZONE_ERROR),
                        color: 'red',
                        autoClose: 5000,
                    })
                }
                maxSize={MAX_UPLOAD_SIZE.value}
                accept={[IMAGE_MIME_TYPE, PDF_MIME_TYPE, MS_WORD_MIME_TYPE, MS_EXCEL_MIME_TYPE, MS_POWERPOINT_MIME_TYPE, 'message/rfc822']}
            >
                <Paper
                    data-bg={'surface-0'}
                    data-brdr={'default-dashed'}
                >
                    <Container clean align='center' justify="center" gap="xl" style={{pointerEvents: 'none'}} p={8}>
                        <Dropzone.Idle>
                            <IconPhoto size={32} data-c={'primary'} stroke={1.5}/>
                        </Dropzone.Idle>
                        <Container direction={'column'} clean>
                            <Text size="xs">
                                {t(TRANSLATION_KEYS.SYSTEM_DROPZONE)}
                            </Text>
                            <Text size="xs">
                                {t(TRANSLATION_KEYS.SYSTEM_DROPZONE_MAX)}{MAX_UPLOAD_SIZE.label}
                            </Text>
                        </Container>
                    </Container>
                </Paper>
            </Dropzone>
            {attachments && attachments.length > 0 &&
                <>
                    <Divider label={t(TRANSLATION_KEYS.SYSTEM_FILES)}/>
                    <Container gap={8} wrap={'wrap'} data-ovf={'scrolly'} mah={100} p={8}>
                        {attachments.map((attachment, index) => {
                            return (
                                <AttachmentItem
                                    attachment={attachment}
                                    handleRemoveAttachment={handleRemoveAttachment}
                                />

                            );
                        })}
                    </Container>
                </>
            }
            <Container clean fw justify={'end'} gap={8}>
                <Button
                    bgVariant={'secondary'}
                    onClick={() => {
                        handleCancel();
                    }}
                >
                    {t(TRANSLATION_KEYS.SYSTEM_CANCEL)}
                </Button>
                <Button
                    onClick={() => {
                        handleSave();
                    }}
                    loading={isSendingMessage}
                >
                    {t(TRANSLATION_KEYS.SEND)}
                </Button>
            </Container>
        </Container>
    );
}