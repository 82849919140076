import httpsModule from '../../util/http';
import {SUPPORT_BASE_URL} from "../../util/constants";

const SUPPORT_PRODUCT_ENDPOINT = SUPPORT_BASE_URL + 'products/';

const productController = {
    getProducts: async () => {
        const response = await httpsModule.get(SUPPORT_PRODUCT_ENDPOINT);
        return response.data;
    },
    getRelatedErrorsByProductName: async (name) => {
        const response = await httpsModule.get(SUPPORT_PRODUCT_ENDPOINT + `errors/related/${name}`);
        return response.data;
    },
};

export default productController;