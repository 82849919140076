import {Flex} from "@mantine/core";
import styles from "./Container.module.css";
import {forwardRef} from "react";

const Container = forwardRef(({
                                  styleVariant = "styled",
                                  fw = false,
                                  fh = false,
                                  clean = false,
                                  children,
                                  ...props
                              }, ref) => {
    return (
        <Flex
            w={fw ? "100%" : null}
            h={fh ? "100%" : null}
            style-variant={styleVariant === "unstyled" || clean ? "unstyled" : "styled"}
            classNames={styles}
            ref={ref}
            {...props}
        >
            {children}
        </Flex>
    )
})

export default Container;