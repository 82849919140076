import {createContext, useContext, useEffect, useState} from 'react';

import {SUPPORTED_CURRENCIES_FORMATTERS, SUPPORTED_LANGUAGES} from '../util/constants';
import {useTranslation} from '../util/localization';
import {STORAGE} from '../util/localStorage';

const LanguageContext = createContext();

const useLanguage = () => {
    return useContext(LanguageContext);
};

const LanguageProvider = ({children}) => {
    const [language, setLanguage] = useState('');
    const [currencyFormat, setCurrencyFormat] = useState('');
    const [dayjsfmt, setDayjsfmt] = useState({
        slashDate: 'DD/MM/YY',
        slashFullDate: 'DD/MM/YYYY',
        slashFullDateTime: 'DD/MM/YYYY HH:mm',
        slashYearFirstDate: 'YY/MM/DD',
        slashYearFirstFullDate: 'YYYY/MM/DD',
        slashYearFirstFullDateTime: 'YYYY/MM/DD/ HH:mm',
        dashDate: 'DD-MM-YY',
        dashFullDate: 'DD-MM-YYYY',
        dashFullDateTime: 'DD-MM-YYYY HH:mm',
        dashYearFirstDate: 'YY-MM-DD',
        dashYearFirstFullDate: 'YYYY-MM-DD',
        dashYearFirstFullDateTime: 'YYYY-MM-DD HH:mm',
        descriptiveDate: 'DD MMM YY',
        descriptiveDateTime: 'DD MMM YY HH:mm',
        descriptiveDateTimeNoYear: 'DD MMM HH:mm',
        descriptiveFullDate: 'DD MMM YYYY',
        descriptiveYearFirstDate: 'YY MMM DD',
        descriptiveYearFirstDateTime: 'YY MMM DD HH:mm',
        descriptiveYearFirstFullDate: 'YYYY MMM DD',
        iso: 'YYYY-MM-DDTHH:mm:ss.SSS[Z]',
        isoShort: 'YYYY-MM-DDTHH:mm:ss[Z]',
        isoDate: 'YYYY-MM-DD',
        isoTime: 'HH:mm:ss.SSS',
        isoTimeShort: 'HH:mm:ss',
        isoWithOffset: 'YYYY-MM-DDTHH:mm:ssZZ'
    });
    const {t} = useTranslation(language);

    const getTimeZoneOffset = (tz) => {
        const offsetPart = new Intl.DateTimeFormat('en-US', {
            timeZone: tz,
            timeZoneName: 'shortOffset'
        }).formatToParts(new Date()).find(part => part.type === 'timeZoneName')?.value;

        return offsetPart ? ` (UTC${offsetPart.replace('GMT', '')})` : ' (UTC 0)';
    };

    const getTimeZones = () => {
        return Intl.supportedValuesOf('timeZone').map((tz) => {
            const offsetStr = getTimeZoneOffset(tz);

            let parts = tz.split('/');
            if (parts.length === 3) {
                parts = [parts[0], parts[2]]; // Mantener solo continente y ciudad
            }
            const cleanedTz = parts.join('/');

            const label = cleanedTz.replace(/\//g, ' - ').replace(/_/g, ' ');

            return {value: cleanedTz, label: `${label}${offsetStr}`};
        });
    };

    const [selectableTimezones, setSelectableTimezones] = useState(getTimeZones);

    /*
        create an object wich contains the neccessary information to format numbers into currency and is created only once, using this approach instead of
        just calling .toLocaleString allow us to avoid performing the search that .toLocaleString performs in every call
        more info: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/toLocaleString
    */
    const currencyTemplate =
        currencyFormat === SUPPORTED_CURRENCIES_FORMATTERS.USA
            ? new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD'
            })
            : new Intl.NumberFormat('es-UY', {
                style: 'currency',
                currency: 'USD'
            });

    const formatCurrency = (value) => {
        if (value !== undefined && value !== null) {
            return currencyTemplate.format(value);
        } else {
            return 'N/A';
        }
    };

    const dateTemplate = new Intl.DateTimeFormat('es-UY', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
    });

    const dateTemplateWithTime = new Intl.DateTimeFormat('es-UY', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
    });

    const formatDate = (value, useTime = false) => {
        let template = useTime ? dateTemplateWithTime : dateTemplate;
        if (value !== undefined && value !== null) {
            return template.format(value);
        } else {
            return 'N/A';
        }
    };

    useEffect(() => {
        setLanguage(STORAGE.get(STORAGE.LANGUAGE) || SUPPORTED_LANGUAGES.SPANISH);
        setCurrencyFormat(STORAGE.get(STORAGE.CURRENCY_FORMATTER) || SUPPORTED_CURRENCIES_FORMATTERS.LOCALE);
    }, []);

    const provider = {
        language,
        setLanguage: (lang) => {
            STORAGE.set(STORAGE.LANGUAGE, lang);
            setLanguage(lang);
        },
        t,
        languageOptions: [
            {value: SUPPORTED_LANGUAGES.ENGLISH, label: 'EN - English'},
            {value: SUPPORTED_LANGUAGES.SPANISH, label: 'ES - Spanish'},
            {value: SUPPORTED_LANGUAGES.PORTUGUESE, label: 'PT - Portuguese'}
        ],
        currencyFormat,
        setCurrencyFormat: (formatter) => {
            STORAGE.set(STORAGE.CURRENCY_FORMATTER, formatter);
            setCurrencyFormat(formatter);
        },
        currencyFormatOptions: [
            {value: SUPPORTED_CURRENCIES_FORMATTERS.USA, label: 'Formato estadounidense ej: (1,000,000.00)'},
            {value: SUPPORTED_CURRENCIES_FORMATTERS.LOCALE, label: 'Formato local ej: (US$ 1.000.000,00)'},
        ],
        formatCurrency,
        formatDate,
        selectableTimezones,
        dayjsfmt
    };
    return (
        <LanguageContext.Provider value={provider}>
            {children}
        </LanguageContext.Provider>
    );
};

export {LanguageProvider, useLanguage};