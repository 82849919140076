import Container from '../../Container/Container';
import HelpTooltip from '../../help/HelpTooltip';
import PartialLoader from '../../spinner/PartialLoader';
import Text from '../../Text/Text';

export default function CardWithTitle({title, help, rightSection, children,headerBg = 'surface-0', isLoading}) {
    return (
        <Container fw fh direction={'column'} data-bg={headerBg} pos={'relative'}>
            <PartialLoader isLoading={isLoading}/>
            <Container clean data-brdr={'default-bottom'} p={8} gap={8} data-ovf={'visible'}>
                <Text size={'md'}>{title}</Text>
                {help && <HelpTooltip help={help}/>}
                {rightSection || null}
            </Container>
            {children}
        </Container>
    );
}