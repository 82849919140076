import {SUPPORT_BASE_URL} from '../../util/constants';
import httpsModule from '../../util/http';

const SUPPORT_ENDPOINT = SUPPORT_BASE_URL + 'tickets/';

const ticketController = {
    createTicket: async (params) => {
        const response = await httpsModule.post(`${SUPPORT_ENDPOINT}create`, params);
        return response.data;
    },
    getTicketsByUser: async () => {
        const response = await httpsModule.get(`${SUPPORT_ENDPOINT}users`);
        return response.data;
    },
    getFullAttachment: async (attachmentId) => {
        const response = await httpsModule.get(`${SUPPORT_ENDPOINT}attachment/${attachmentId}`);
        return response.data;
    },
};

export default ticketController;