import {IMAGE_MIME_TYPE, MS_EXCEL_MIME_TYPE, MS_POWERPOINT_MIME_TYPE, MS_WORD_MIME_TYPE, PDF_MIME_TYPE} from '@mantine/dropzone';
import {notifications} from '@mantine/notifications';

import {MAX_UPLOAD_SIZE} from '../../util/constants';
import {useTranslation} from '../../util/localization';
import {TRANSLATION_KEYS} from '../../util/translation-keys';

export default function usePasteFile(setAttachments, setAddAttachments = null) {
    const {t} = useTranslation();

    const getUniqueFileName = (fileName, existingFiles) => {
        const nameParts = fileName.split('.');
        const extension = nameParts.pop();
        const baseName = nameParts.join('.');

        let newName = fileName;
        let counter = 1;

        while (existingFiles.some(file => file.name === newName)) {
            newName = `${baseName}(${counter}).${extension}`;
            counter++;
        }

        return newName;
    };

    const handlePaste = (event) => {
        const clipboardData = event.clipboardData;
        const files = clipboardData.files;

        if (files.length > 0) {
            event.preventDefault();

            const validFiles = Array.from(files).filter((file) => {
                const isValidType = [
                    ...IMAGE_MIME_TYPE,
                    ...PDF_MIME_TYPE,
                    ...MS_WORD_MIME_TYPE,
                    ...MS_EXCEL_MIME_TYPE,
                    ...MS_POWERPOINT_MIME_TYPE,
                    'message/rfc822',
                ].includes(file.type);
                const isValidSize = file.size <= MAX_UPLOAD_SIZE.value;

                if (!isValidSize || !isValidType) {
                    notifications.show({
                        title: t(TRANSLATION_KEYS.ERROR_ALERT),
                        message: t(TRANSLATION_KEYS.SYSTEM_DROPZONE_ERROR),
                        color: 'red',
                        autoClose: 5000,
                    });
                }
                return isValidType && isValidSize;
            });

            if (validFiles.length > 0) {
                setAttachments((prev) => {
                    const newFiles = validFiles.map((file) => {
                        const uniqueName = getUniqueFileName(file.name, prev);
                        return new File([file], uniqueName, {type: file.type});
                    });
                    return [...prev, ...newFiles];
                });
                setAddAttachments && setAddAttachments(true);
            }
        }
    };

    return {handlePaste};
}