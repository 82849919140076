import jwt_decode from 'jwt-decode';
import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';

import {isTokenExpired} from '../util/functions';
import {STORAGE} from '../util/localStorage';

const TokenChecker = ({setLoggedIn}) => {
    const navigate = useNavigate();
    useEffect(() => {

        const checkToken = () => {
            const token = STORAGE.get(STORAGE.TOKEN);

            if (token) {
                const decoded = jwt_decode(token);
                if (isTokenExpired(decoded?.exp)) {
                    setLoggedIn(false);
                    STORAGE.remove(STORAGE.TOKEN);
                    navigate('/login');
                }
            }
        };

        checkToken();
        const interval = setInterval(checkToken, 300000);

        return () => clearInterval(interval);
    }, []);

    return null;
};

export default TokenChecker;