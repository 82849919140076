import {LoadingOverlay} from '@mantine/core';
import {notifications} from '@mantine/notifications';
import {IconPaperclip, IconX} from '@tabler/icons-react';
import {useState} from 'react';

import {useLanguage} from '../../../contexts/LanguageContext';
import ticketController from '../../../controller/support-service/ticketController';
import {handleDownload} from '../../../util/functions';
import {TRANSLATION_KEYS} from '../../../util/translation-keys';
import Container from '../Container/Container';
import Text from '../Text/Text';

export default function AttachmentItem({attachment, handleRemoveAttachment, showRemove = true, downloadPrefix = null}) {
    const {t} = useLanguage();
    const [loading, setLoading] = useState(false);

    const refetchFullAttachment = async () => {
        try {
            const fullAttachment = await ticketController.getFullAttachment(attachment?.id);
            return fullAttachment;
        } catch (e) {
            notifications.show({
                title: t(TRANSLATION_KEYS.ERROR_ALERT),
                message: t(TRANSLATION_KEYS.SYSTEM_DROPZONE_ERROR),
                color: 'red',
                autoClose: 5000,
            });
        }
    };

    const handleClick = async () => {
        setLoading(true);
        const result = await refetchFullAttachment();
        if (result) {
            handleDownload(result, downloadPrefix);
        }
        setLoading(false);
    };

    return (
        attachment &&
        <Container
            p={2}
            gap={4}
            data-ovf={'visible'}
            align={'center'}
            data-crsr={'pointer'}
        >
            <Container
                data-ovf={'visible'} clean
                w={'1rem'}
                pos={'relative'}
            >
                <LoadingOverlay visible={loading} overlayProps={{radius: 'xs', blur: 1}} loaderProps={{size: 12}}/>
                <IconPaperclip
                    data-c={'primary'}
                />
            </Container>
            <Container
                clean
                data-ovf={'visible'}
                pr={showRemove ? null : '0.5rem'}
                onClick={() => handleClick()}
            >
                <Text
                    w={'auto'}
                >
                    {attachment.name}
                </Text>
            </Container>
            {showRemove &&
                <Container data-ovf={'visible'} clean w={'1rem'}>
                    <IconX
                        data-crsr={'pointer'}
                        onClick={() => handleRemoveAttachment(attachment.name)}
                    />
                </Container>
            }
        </Container>
    );
}