import {z} from 'zod';

import {TRANSLATION_KEYS} from '../../util/translation-keys';

const AttachmentSchema = z.object({
    name: z.string().min(1),
    content: z.string().min(1)
});

const ProductSchema =
    z
        .object({
            id: z.number(),
            name: z.string()
        })
        .nullable()
        .refine(val => val !== null, {
            message: TRANSLATION_KEYS.VALIDATION_NOT_EMPTY_PRODUCT,
        });

const ErrorSchema =
    z
        .object({
            id: z.number(),
            name: z.string()
        })
        .nullable()
        .refine(val => val !== null, {
            message: TRANSLATION_KEYS.VALIDATION_NOT_EMPTY_ERROR,
        });

const NewTicketSchema = z.object({
    description: z.string().min(1, TRANSLATION_KEYS.VALIDATION_NOT_EMPTY_DESCRIPTION),
    relatedUsers: z.array(z.string()).optional(),
    attachments: z.array(AttachmentSchema).optional(),
    product: ProductSchema,
    error: ErrorSchema
});

export const validateNewTicket = (data) => {
    const result = NewTicketSchema.safeParse(data);
    if (!result.success) {
        return result.error.errors;
    }
    return null;
};