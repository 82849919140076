import React from "react";
import {Select as MantineSelect} from "@mantine/core";

import styles from "./Select.module.css";

/**
 * @description Wrapper custom component for Mantine Select
 * @param size - Size of the select | default: xs | xs | sm | md | lg | xl | xxl
 * @param searchable - Enable search input | default: true
 */
export default function Select({size = "xs", allowDeselect = false, searchable = true, ...props}) {
    return (
        <MantineSelect
            size={size}
            searchable={searchable}
            allowDeselect={allowDeselect}
            classNames={styles}
            comboboxProps={{shadow: "md"}}
            {...props}
        />
    )
}