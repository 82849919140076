export const TRANSLATION_KEYS = {
    CLIENT: 'client',
    CLIENT_CODE: 'clientCode',
    CLIENT_NAME: 'clientName',
    COMMERCIAL: 'commercial',
    COMMERCIALS: 'commercials',
    DATE: 'date',
    DATE_WEEK: 'dateWeek',
    DATE_MONTH: 'dateMonth',
    DESTINATION: 'destination',
    DESTINATIONS: 'destinations',
    SERVICE: 'service',
    OPERATIVE: 'operative',
    OPERATIVES: 'operatives',
    RELATED_OPERATIVES: 'relatedOperatives',
    PROVIDER: 'provider',
    TRANSPORT: 'transport',
    MODE: 'mode',
    TYPE: 'type',
    TRANSPORT_MODE: 'transportMode',
    TRANSPORT_MODES: 'transportModes',
    CONTAINERS: 'containers',
    CONTAINER_NUMBER: 'containerNumber',
    CONTAINER_TYPE: 'containerType',
    PRODUCT: 'product',
    ERROR_TYPE: 'errorType',
    TRUCKS: 'trucks',
    COMPANY: 'company',
    ORIGIN: 'origin',
    ORIGINS: 'origins',
    INCOTERM: 'incoterm',
    PACKING: 'packing',
    PICKUP: 'pickups',
    COMMODITY: 'commodity',
    COMMODITIES: 'commodities',
    GOODS_VALUE: 'goodsValue',
    INSURANCE_VALUE: 'insuranceValue',
    INSURANCE: 'insurance',
    TOTAL_WEIGHT: 'totalWeight',
    TOTAL_VOLUME: 'totalVolume',
    TOTAL_CHARGEABLE: 'totalChargeable',
    TOTAL_CARTONS: 'totalCartons',
    TOTAL_PALLETS: 'totalPallets',
    STACKABLE: 'stackable',
    VIEW_DOCS: 'viewDocs',
    VIEW_NOTES: 'viewNotes',
    DOCUMENTS: 'documents',
    NOTES: 'notes',
    CODE: 'code',
    CONCEPT: 'concept',
    CONCEPT_EDITABLE: 'conceptEditable',
    TOTAL: 'total',
    PROVIDER_REFERENCE: 'providerReference',
    TOOLTIP_PROVIDER_REFERENCE: 'tooltipProviderReference',
    TITLE: 'title',
    DESCRIPTION: 'description',
    RELATED_USERS: 'relatedUsers',
    VALIDITY: 'validity',
    ROUTE: 'route',
    TRANSIT_TIME: 'transitTime',
    TRANSIT_TIME_FILTER: 'transitTimeFilter',
    CARRIER: 'carrier',
    FREE_DAYS: 'freeDays',
    COMMENTS: 'comments',
    COMMENTS_AND_CONDITIONS: 'commentsAndConditions',
    COST: 'cost',
    QUANTITY: 'quantity',
    WEIGHT: 'weight',
    LENGTH: 'length',
    WIDTH: 'width',
    HEIGHT: 'height',
    RANGE: 'range',
    RANGE_WEIGHT: 'rangeWeight',
    RANGE_SIZE: 'rangeSize',
    ADD_NEW_COST: 'addNewCost',
    ERROR_ALERT: 'errorAlert',
    ERROR_WHILE_GETTING_QUOTATION: 'errorWhileGettingQuotation',
    ERROR_CANNOT_ADD_MORE_CONCEPTS: 'errorCannotAddMoreConcepts',
    ERROR_AT_LEAST_ONE_COST: 'errorAtLeastOneCost',
    ERROR_AT_LEAST_ONE_CHARGE: 'errorAtLeastOneCharge',
    ERROR_AT_LEAST_ONE_VALUE: 'errorAtLeastOneValue',
    ERROR_EVERY_CHARGE_NEEDS_A_CODE: 'errorEveryChargeNeedsACode',
    ERROR_WRONG_DATE_FORMAT: 'errorWrongDateFormat',
    ERROR_SENDING_COSTS: 'errorSendingCosts',
    ERROR_PERFORMING_TASK: 'errorPerformingTask',
    SEND: 'send',
    SEND_COSTS: 'sendCosts',
    SEND_COSTS_CONFIRMATION: 'sendCostsConfirmation',
    SEND_COSTS_SUCCESS: 'sendCostsSuccess',
    SUCCESS_ALERT: 'successAlert',
    TOOLTIP_COST_PER_KG: 'tooltipCostPerKg',
    TOOLTIP_DELETE_CHARGE: 'tooltipDeleteCharge',
    TOOLTIP_DELETE_COST: 'tooltipDeleteCost',
    TOOLTIP_ADD_CHARGE: 'tooltipAddCharge',
    TOOLTIP_ADD_COST: 'tooltipAddCost',
    TOOLTIP_DUPLICATE_COST: 'tooltipDuplicateCost',
    COST_ALREADY_QUOTED: 'costAlreadyQuoted',
    QUOTATION_HAS_BEEN_CANCELED: 'quotationHasBeenCanceled',
    CLOSE: 'close',
    YES: 'yes',
    NO: 'no',
    SHORTCUT: 'shortcut',
    SETTINGS: 'settings',
    MAXIMIZE: 'maximize',
    MINIMIZE: 'minimize',
    LIMITED_INPUT: 'limitedInput',
    ACCUMULATED: 'accumulated',
    DAILY: 'daily',
    DAILY_AVERAGE: 'dailyAverage',
    REQUESTED: 'requested',
    SENT: 'sent',
    PENDING: 'pending',
    UPDATED: 'updated',
    QUOTED: 'quoted',
    APPROVED: 'approved',
    NOT_APPROVED: 'notApproved',
    FUTURE: 'future',
    EXPRESS: 'express',
    SEA: 'sea',
    AIR: 'air',
    ROAD: 'road',
    AGENT: 'agent',
    PRICING: 'pricing',
    REQUESTS: 'requests',
    QUOTATIONS: 'quotations',
    TOTAL_QUOTATIONS: 'totalQuotations',
    TOTAL_REQUESTS: 'totalRequests',
    EFFICIENCY: 'efficiency',
    CREATE_NEW_NOTE: 'createNewNote',
    CREATE_NEW_EVENT: 'createNewEvent',
    //DASHBOARD
    DASHBOARD_TAB_GENERAL: 'dashboardTabGeneral',
    DASHBOARD_TAB_GENERAL_HELP_1: 'dashboardTabGeneralHelp1',
    DASHBOARD_TAB_FCL_HELP_1: 'dashboardTabFclHelp1',
    DASHBOARD_FULLSCREEN_MODE_ON: 'dashboardFullScreenModeOn',
    DASHBOARD_FULLSCREEN_MODE_OFF: 'dashboardFullScreenModeOff',
    DASHBOARD_FULLSCREEN_MODE_DESCRIPTION: 'dashboardFullScreenModeDescription',
    DASHBOARD_PRESENTATION_MODE_ON: 'dashboardPresentationModeOn',
    DASHBOARD_PRESENTATION_MODE_OFF: 'dashboardPresentationModeOff',
    DASHBOARD_PRESENTATION_MODE_DESCRIPTION_1: 'dashboardPresentationModeDescription1',
    DASHBOARD_PRESENTATION_MODE_DESCRIPTION_2: 'dashboardPresentationModeDescription2',
    DASHBOARD_CLEAR_FILTERS: 'dashboardClearFilters',
    DASHBOARD_CLEAR_FILTERS_DESCRIPTION: 'dashboardClearFiltersDescription',
    DASHBOARD_MONTHLY_RESULTS: 'dashboardMonthlyResults',
    DASHBOARD_CUSTOM_Y_AXIS: 'dashboardCustomYAxis',
    DASHBOARD_Y_AXIS_LIMIT: 'dashboardYAxisLimit',
    DASHBOARD_COMMERCIAL_MONTH: 'dashboardCommercialMonth',
    DASHBOARD_QUOTATION_EFFICIENCY: 'dashboardQuotationEfficiency',
    DASHBOARD_QUICK_VIEW: 'dashboardQuickView',
    DASHBOARD_USE_POLYGONAL_BACKGROUND: 'dashboardUsePolygonalBackground',
    DASHBOARD_USE_CIRCULAR_BACKGROUND: 'dashboardUseCircularBackground',
    DASHBOARD_USE_NUMBERS: 'dashboardUseNumbers',
    DASHBOARD_USE_PERCENTAGES: 'dashboardUsePercentages',
    DASHBOARD_USE_POLAR_CHART: 'dashboardUsePolarChart',
    DASHBOARD_USE_STACKED_CHART: 'dashboardUseStackedChart',
    DASHBOARD_SHOW_EFFICIENCY: 'dashboardShowEfficiency',
    DASHBOARD_SHOW_TOTAL: 'dashboardShowTotal',
    DASHBOARD_USE_BAR_CHART: 'dashboardUseBarChart',
    DASHBOARD_PERCENTAGE: 'dashboardPercentage',
    DASHBOARD_WAITING_TIME: 'dashboardWaitingTime',
    DASHBOARD_WAITING_TIME_HELP_1: 'dashboardWaitingTimeHelp1',
    DASHBOARD_WAITING_TIME_HELP_2: 'dashboardWaitingTimeHelp2',
    DASHBOARD_MONTHLY_QUOTATIONS: 'dashboardMonthlyQuotations',
    DASHBOARD_VISUALIZING_INFO: 'dashboardVisualizingInfo',
    DASHBOARD_AIR_COUNT: 'dashboardAirCount',
    DASHBOARD_FCL_COUNT: 'dashboardFclCount',
    DASHBOARD_LCL_COUNT: 'dashboardLclCount',
    DASHBOARD_RORO_COUNT: 'dashboardRoroCount',
    DASHBOARD_FTL_COUNT: 'dashboardFtlCount',
    DASHBOARD_LTL_COUNT: 'dashboardLtlCount',
    DASHBOARD_COMMERCIAL_RANKING: 'dashboardCommercialRanking',
    DASHBOARD_POSITION: 'dashboardPosition',
    DASHBOARD_MOST_REQUESTED: 'dashboardMostRequested',
    DASHBOARD_TOTALS: 'dashboardTotals',
    DASHBOARD_AIR_SUMMARY: 'dashboardAirSummary',
    DASHBOARD_AIR_HISTORY: 'dashboardAirHistory',
    DASHBOARD_LSE_SUMMARY: 'dashboardLseSummary',
    DASHBOARD_LSE_HISTORY: 'dashboardLseHistory',
    DASHBOARD_ULD_SUMMARY: 'dashboardUldSummary',
    DASHBOARD_ULD_HISTORY: 'dashboardUldHistory',
    DASHBOARD_LCL_SUMMARY: 'dashboardLclSummary',
    DASHBOARD_LCL_HISTORY: 'dashboardLclHistory',
    DASHBOARD_FCL_SUMMARY: 'dashboardFclSummary',
    DASHBOARD_FCL_HISTORY: 'dashboardFclHistory',
    DASHBOARD_HISTORY_DESCRIPTION_CARRIER: 'dashboardHistoryDescriptionCarrier',
    DASHBOARD_HISTORY_DESCRIPTION_PROVIDER: 'dashboardHistoryDescriptionProvider',
    DASHBOARD_ADD_COST_COMPARISON: 'dashboardAddCostComparison',
    DASHBOARD_REMOVE_COST_COMPARISON: 'dashboardRemoveCostComparison',
    DASHBOARD_VIEW_DETAILS: 'dashboardViewDetails',
    DASHBOARD_ADD_ALL_COSTS: 'dashboardAddAllCosts',
    DASHBOARD_REMOVE_ALL_COSTS: 'dashboardRemoveAllCosts',
    DASHBOARD_USE_CUSTOM_AXIS_LIMIT: 'dashboardUseCustomAxisLimit',
    DASHBOARD_USE_CUSTOM_AXIS_LIMIT_DESCRIPTION: 'dashboardUseCustomAxisLimitDescription',
    DASHBOARD_USE_WEEKS: 'dashboardUseWeeks',
    DASHBOARD_USE_MONTHS: 'dashboardUseMonths',
    DASHBOARD_USE_PROVIDER_VIEW: 'dashboardUseProviderView',
    DASHBOARD_USE_CARRIER_VIEW: 'dashboardUseCarrierView',
    COMMERCIAL_DASHBOARD_CLIENT_TAB: 'commercialDashboardClientTab',
    COMMERCIAL_DASHBOARD_OPERATIVE_TAB: 'commercialDashboardOperativeTab',
    ADMINISTRATIVE_DASHBOARD_SELLS_TAB: 'administrativeDashboardSellsTab',
    ADMINISTRATIVE_DASHBOARD_COSTS_TAB: 'administrativeDashboardCostsTab',
    CLEAR_FILTERS_DESCRIPTION: 'clearFiltersDescription',
    SORT: 'sort',
    SORT_BY: 'sortBy',
    WEEK: 'week',
    VISUALIZING: 'visualizing',
    MISSING_SEARCH_PARAMS: 'missingSearchParams',
    //DATES
    TODAY: 'today',
    PAST_DATES: 'pastDates',
    YESTERDAY: 'yesterday',
    LAST_7_DAYS: 'last7Days',
    LAST_14_DAYS: 'last14Days',
    LAST_MONTH: 'lastMonth',
    LAST_2_MONTHS: 'last2Months',
    LAST_3_MONTHS: 'last3Months',
    LAST_6_MONTHS: 'last6Months',
    LAST_12_MONTHS: 'last12Months',
    FUTURE_DATES: 'futureDates',
    TOMORROW: 'tomorrow',
    NEXT_7_DAYS: 'next7Days',
    NEXT_14_DAYS: 'next14Days',
    NEXT_MONTH: 'nextMonth',
    NEXT_2_MONTHS: 'next2Months',
    NEXT_3_MONTHS: 'next3Months',
    NEXT_6_MONTHS: 'next6Months',
    NEXT_12_MONTHS: 'next12Months',
    CUSTOM_DATE: 'customDate',
    //EVENTS
    EVENTS_DAYS_TO_EXPIRE: 'eventsDaysToExpire',
    EVENTS_EXPIRED_DAYS: 'eventsExpiredDays',
    EVENTS_NEW_DUE_DATE: 'eventsNewDueDate',
    EVENTS_SHOW_COMPLETED: 'eventsShowCompleted',
    EVENTS_HIDE_COMPLETED: 'eventsHideCompleted',
    //OPERATION
    OPERATION_INFORMATION: 'operationInformation',
    OPERATION_INFORMATION_HELP: 'operationInformationHelp',
    OPERATION_TIMELINE: 'operationTimeline',
    OPERATION_TIMELINE_HELP: 'operationTimelineHelp',
    OPERATION_NOTES: 'operationNotes',
    OPERATION_NOTES_HELP: 'operationNotesHelp',
    OPERATION_LAST_CREATED_NOTE: 'operationLastCreatedNote',
    OPERATION_EVENTS: 'operationEvents',
    OPERATION_EVENTS_HELP: 'operationEventsHelp',
    OPERATION_EVENTS_SORT_EXPIRE: 'operationEventsSortExpire',
    OPERATION_EVENTS_SORT_CREATE: 'operationEventsSortCreate',
    OPERATION_EVENTS_INCLUDE_ALL: 'operationEventsIncludeAll',
    OPERATION_NON_EDITABLE: 'operationNonEditable',
    OPERATION_NON_EDITABLE_SHORT: 'operationNonEditableShort',
    OPERATION_ENABLE_HIGHLIGHT: 'operationEnableHighlight',
    OPERATION_DISABLE_HIGHLIGHT: 'operationDisableHighlight',
    OPERATION_COLORS_BY_STATUS: 'operationColorsByStatus',
    OPERATION_NOTE_TYPE: 'operationNoteType',
    OPERATION_NOTE_TYPE_INTERNAL: 'operationNoteTypeInternal',
    OPERATION_NOTE_TYPE_CLIENT: 'operationNoteTypeClient',
    STATUS: 'status',
    STATUS_CANCELED: 'statusCanceled',
    STATUS_ON_HOLD: 'statusOnHold',
    STATUS_IN_COORDINATION: 'statusInCoordination',
    STATUS_IN_TRANSIT: 'statusInTransit',
    STATUS_AT_BORDER: 'statusAtBorder',
    STATUS_ARRIVED: 'statusArrived',
    STATUS_PAYMENT_PENDING: 'statusPaymentPending',
    STATUS_CLOSURE_PENDING: 'statusClosurePending',
    STATUS_CLOSED: 'statusClosed',
    //SYSTEM
    SYSTEM_ALL_DATES: 'systemAllDates',
    SYSTEM_NOT_ASSIGNED: 'systemNotAssigned',
    SYSTEM_UNSAVED_CHANGES: 'systemUnsavedChanges',
    SYSTEM_NOT_FOUND: 'systemNotFound',
    SYSTEM_SERVER_ERROR: 'systemServerError',
    SYSTEM_NOTHING_SELECTED: 'systemNothingSelected',
    SYSTEM_NO_EVENTS: 'systemNoEvents',
    SYSTEM_NO_NOTIFICATIONS: 'systemNoNotifications',
    SYSTEM_INDEX: 'systemIndex',
    SYSTEM_CREATED: 'systemCreated',
    SYSTEM_CREATED_BY: 'systemCreatedBy',
    SYSTEM_DUE: 'systemDue',
    SYSTEM_LAST_MODIFIED: 'systemLastModified',
    SYSTEM_CANCEL: 'systemCancel',
    SYSTEM_SAVE: 'systemSave',
    SYSTEM_FINALIZE: 'systemFinalize',
    SYSTEM_FINALIZED: 'systemFinalized',
    SYSTEM_EDIT: 'systemEdit',
    SYSTEM_POSTPONE: 'systemPostpone',
    SYSTEM_SERVER_SUCCESS: 'systemSuccess',
    SYSTEM_STATUS_UPDATE_SUCCESS: 'systemStatusUpdateSuccess',
    SYSTEM_STATUS_UPDATE_ERROR: 'systemStatusUpdateError',
    SYSTEM_GET_OPERATIONS_ERROR: 'systemGetOperationsError',
    SYSTEM_GET_EVENT_COUNT_ERROR: 'systemGetEventsCountError',
    SYSTEM_GET_EVENT_INFORMATION_ERROR: 'systemGetEventsInformationError',
    SYSTEM_GET_OPERATION_ERROR: 'systemGetOperationError',
    SYSTEM_CREATE_NOTE_SUCCESS: 'systemCreateNoteSuccess',
    SYSTEM_CREATE_NOTE_ERROR: 'systemCreateNoteError',
    SYSTEM_CONFIRMATION_CREATE_NOTE: 'systemConfirmationCreateNote',
    SYSTEM_UPDATE_NOTE_SUCCESS: 'systemUpdateNoteSuccess',
    SYSTEM_UPDATE_NOTE_ERROR: 'systemUpdateNoteError',
    SYSTEM_CREATE_EVENT_SUCCESS: 'systemCreateEventSuccess',
    SYSTEM_CREATE_EVENT_ERROR: 'systemCreateEventError',
    SYSTEM_CONFIRMATION_CREATE_EVENT: 'systemConfirmationCreateEvent',
    SYSTEM_POSTPONE_EVENT_SUCCESS: 'systemPostponeEventSuccess',
    SYSTEM_POSTPONE_EVENT_ERROR: 'systemPostponeEventError',
    SYSTEM_CONFIRMATION_POSTPONE_EVENT: 'systemConfirmationPostponeEvent',
    SYSTEM_FINALIZE_EVENT_SUCCESS: 'systemFinalizeEventSuccess',
    SYSTEM_FINALIZE_EVENT_ERROR: 'systemFinalizeEventError',
    SYSTEM_CONFIRMATION_FINALIZE_EVENT: 'systemConfirmationFinalizeEvent',
    SYSTEM_UPDATE_EVENT_SUCCESS: 'systemUpdateEventSuccess',
    SYSTEM_UPDATE_EVENT_ERROR: 'systemUpdateEventError',
    SYSTEM_GET_STATUS_ERROR: 'systemGetStatusError',
    SYSTEM_FILTERS_VISIBILITY: 'systemFiltersVisibility',
    SYSTEM_GET_USERS_ERROR: 'systemGetUsersError',
    SYSTEM_DROPZONE: 'systemDropzone',
    SYSTEM_DROPZONE_MAX: 'systemDropzoneMax',
    SYSTEM_DROPZONE_ERROR: 'systemDropzoneError',
    SYSTEM_REFRESH: 'systemRefresh',
    SYSTEM_NEW_MESSAGE: 'systemNewMessage',
    SYSTEM_FILES: 'systemFiles',
    SYSTEM_DETAILS: 'systemDetails',
    SYSTEM_SHOW_DETAILS: 'systemShowDetails',
    SYSTEM_HIDE_DETAILS: 'systemHideDetails',
    SYSTEM_VISIBILITY: 'systemVisibility',
    SYSTEM_GET_OPERATIVE_ERROR: 'systemGetOperativeError',
    SYSTEM_GET_RELATED_OPERATIVE_ERROR: 'systemGetRelatedOperativeError',
    SYSTEM_UPDATE_OPERATIVE_ERROR: 'systemUpdateOperativeError',
    //SUPPORT
    SUPORT_REPORT_A_BUG: 'supportReportABug',
    SUPPORT_NEW_INCIDENCE: 'supportNewIncidence',
    SUPPORT_NEW_INCIDENCE_HELP: 'supportNewIncidenceHelp',
    SUPPORT_CREATE_TICKET_SUCCESS: 'supportCreateTicketSuccess',
    SUPPORT_CREATE_TICKET_ERROR: 'supportCreateTicketError',
    SUPPORT_GET_TICKET_ERROR: 'supportGetTicketError',
    SUPPORT_TICKETS_SHOW_COMPLETED: 'supportTicketsShowCompleted',
    SUPPORT_TICKETS_HIDE_COMPLETED: 'supportTicketsHideCompleted',
    SUPPORT_CREATE_TICKET: 'supportCreateTicket',
    SUPPORT_PRIORITY: 'supportPriority',
    PRIORITY_VERY_LOW: 'priorityVeryLow',
    PRIORITY_LOW: 'priorityLow',
    PRIORITY_MEDIUM: 'priorityMedium',
    PRIORITY_HIGH: 'priorityHigh',
    PRIORITY_CRITIC: 'priorityCritic',
    NOT_STARTED: 'notStarted',
    IN_PROGRESS: 'inProgress',
    BLOCKED: 'blocked',
    COMPLETED: 'completed',
    GENERAL: 'general',
    E_INVOICING_UY: 'eInvoicingUy',
    E_INVOICING_CL: 'eInvoicingCl',
    CARGO_WISE: 'cargoWise',
    COTIZADOR: 'cotizador',
    SUPPORT_STATUS: 'supportStatus',
    SUPPORT_GET_TICKET_MESSAGE_ERROR: 'supportGetTicketMessageError',
    SUPPORT_SEND_TICKET_MESSAGE_ERROR: 'supportSendTicketMessageError',
    SUPPORT_CLOSED_TICKET: 'supportClosedTicket',
    SUPPORT_MESSAGE_AND_DETAILS: 'supportMessageAndDetails',
    SUPPORT_TICKET_LIST_HELP: 'supportTicketListHelp',
    SUPPORT_MARKED_AS_SOLUTION: 'supportMarkedAsSolution',
    SUPPORT_MARKED_AS_SOLUTION_FINALIZED: 'supportMarkedAsSolutionFinalized',
    SUPPORT_MARKED_AS_SOLUTION_CANCELED: 'supportMarkedAsSolutionCanceled',
    SUPPORT_MARKED_AS_SOLUTION_FUTURE: 'supportMarkedAsSolutionFuture',
    SUPPORT_ERROR_WHILE_GETTING_PRODUCTS: 'supportErrorWhileGettingProducts',
    SUPPORT_ERROR_WHILE_GETTING_ERRORS: 'supportErrorWhileGettingErrors',
    SUPPORT_PRODUCT_GENERAL: 'supportProductGeneral',
    SUPPORT_PRODUCT_CARGO_WISE: 'supportProductCargoWise',
    SUPPORT_PRODUCT_COTIZADOR: 'supportProductCotizador',
    SUPPORT_PRODUCT_MAIL: 'supportProductMail',
    SUPPORT_PRODUCT_OTHERS: 'supportProductOthers',
    SUPPORT_PRODUCT_ERROR_OTHERS: 'supportProductErrorOthers',
    SUPPORT_PRODUCT_ERROR_E_INVOICING: 'supportProductErrorEInvoicing',
    SUPPORT_PRODUCT_ERROR_SAVING_QUOTATION: 'supportProductErrorSavingQuotation',
    SUPPORT_PRODUCT_ERROR_SENDING_QUOTATION_CW: 'supportProductErrorSendingQuotationCW',
    SUPPORT_PRODUCT_ERROR_SAVING_COSTS: 'supportProductErrorSavingCosts',
    //VALIDATION
    VALIDATION_DUE_DATE_AFTER_CREATION_DATE: 'validationDueDateAfterCreationDate',
    VALIDATION_NOT_EMPTY_TITLE: 'validationNotEmptyTitle',
    VALIDATION_NOT_EMPTY_DESCRIPTION: 'validationNotEmptyDescription',
    VALIDATION_NOT_EMPTY_PRODUCT: 'validationNotEmptyProduct',
    VALIDATION_NOT_EMPTY_ERROR: 'validationNotEmptyError',
};