import {Loader as MantineLoader} from '@mantine/core';

/**
 * @description A Loader component that is used to display a loading spinner on a certain area of the screen. To ensure correct positioning, the component should be wrapped in a component with position: relative.
 * Is preffered to use this component without conditionally rendering it, because it will give us a smoother transition effect.
 */

export default function PartialLoader({
                                          isLoading,
                                          type = 'dots',
                                          ...props
                                      }) {
    return (
        <MantineLoader
            w={'100%'}
            h={'100%'}
            data-bg={'surface-070'}
            data-c={'primary'}
            type={type}
            style={{
                position: 'absolute',
                top: 0,
                left: 0,
                zIndex: 5,
                opacity: isLoading ? 1 : 0,
                transition: 'opacity 0.3s ease-in-out',
                pointerEvents: isLoading ? 'all' : 'none'
            }}
        />
    );
}