import {ActionIcon, Affix, Collapse} from '@mantine/core';
import {IconBug, IconX} from '@tabler/icons-react';
import {useState} from 'react';
import {useLocation} from 'react-router-dom';

import CardWithTitle from '../../components/base/cards/card-with-title/CardWithTitle';
import Container from '../../components/base/Container/Container';
import {useLanguage} from '../../contexts/LanguageContext';
import {PERMISSION, ROUTES} from '../../util/constants';
import {getAuth} from '../../util/functions';
import {TRANSLATION_KEYS} from '../../util/translation-keys';

import SupportNewTicket from './SupportNewTicket';


export default function SupportNewTicketAffix() {
    const {t} = useLanguage();
    const [isOpen, setIsOpen] = useState(false);
    const location = useLocation();

    return (
        getAuth(PERMISSION.SUPPORT_USER)[0] && !location.pathname.includes(ROUTES.THIRD_PARTY.path) ?
            <>
                {isOpen &&
                    <div
                        onClick={() => {
                            setIsOpen(false);
                        }}
                        style={{
                            backgroundColor: 'black',
                            opacity: 0.5,
                            position: 'absolute',
                            width: '100vw',
                            height: '100vh',
                            zIndex: 1990
                        }}
                    />
                }
                <Affix zIndex={2000} position={{bottom: 8, left: 8}}>
                    <Collapse in={isOpen} mb={8}>
                        <Container clean direction={'column'} justify={'end'} gap={8}>
                            <CardWithTitle
                                title={t(TRANSLATION_KEYS.SUPPORT_NEW_INCIDENCE)}
                                help={[t(TRANSLATION_KEYS.SUPPORT_NEW_INCIDENCE_HELP)]}
                                rightSection={
                                    <Container clean ml={'auto'} justify={'end'}>
                                        <IconX
                                            data-crsr={'pointer'}
                                            data-c={'surface-2'}
                                            onClick={() => setIsOpen(false)}
                                        />
                                    </Container>
                                }
                            >
                                <Container clean w={'35rem'}>
                                    <SupportNewTicket isOpen={isOpen} setCreateNew={setIsOpen} clearFn={() => setIsOpen(false)}/>
                                </Container>
                            </CardWithTitle>
                        </Container>
                    </Collapse>
                    <ActionIcon
                        data-animation-helper={'opacity-hover-01'}
                        data-bg={'tertiary'}
                        onClick={() => {
                            setIsOpen(!isOpen);
                        }}
                    >
                        <IconBug/>
                    </ActionIcon>
                </Affix>
            </>
            : null
    );
}