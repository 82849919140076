import {Popover} from '@mantine/core';
import {useDisclosure} from '@mantine/hooks';
import {IconHelp} from '@tabler/icons-react';
import React from 'react';

import Container from '../Container/Container';
import Text from '../Text/Text';

export default function HelpTooltip({help}) {
    const [opened, {close, open}] = useDisclosure(false);
    return (
        <Popover opened={opened} withArrow zIndex={99999}>
            <Popover.Target>
                <IconHelp size={24} data-c={'surface-2'} onMouseEnter={open} onMouseLeave={close}/>
            </Popover.Target>
            <Popover.Dropdown>
                <Container styleVariant={'unstyled'} direction={'column'} align={'center'} gap={4}>
                    {
                        help?.map((text, index) => (
                            <Text>
                                {text}
                            </Text>
                        ))
                    }
                </Container>
            </Popover.Dropdown>
        </Popover>
    );
}